/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete } from "@mui/material";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";

// Trakib examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate, useParams } from "react-router-dom";

import CrudService from "services/cruds-service";
import i18n from "i18n";

const EditDepartment = () => {
  const { id } = useParams();
  const { t } = i18n;
  const navigate = useNavigate();
  const [units, setUnits] = useState([]);

  const [department, setDepartment] = useState({
    id: null,
    title: null,
    unit_id: null,
  });

  const [error, setError] = useState({
    title: false,
    unit_id: false,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await CrudService.getAllData(
          "items/units?fields=title,id"
        );
        setUnits(data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const response = await CrudService.getAllData(
          `items/departments/${id}?fields=*,unit_id.title,unit_id.id`
        );
        console.log(response.data);
        const getData = response.data;
        const departmentData = {
          id: getData.id,
          title: getData.title,
          unit_id: getData.unit_id,
        };
        setDepartment(departmentData);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [id]);

  const changeHandler = (e) => {
    setDepartment({
      ...department,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!department.title || department.title.trim().length === 0) {
      setError({
        unit_id: false,
        title: true,
        textError: t("required"),
      });
      return;
    }

    if (!department.unit_id) {
      setError({
        title: false,
        unit_id: true,
        textError: t("required"),
      });
      return;
    }

    try {
      const newDepartment = {
        title: department.title,
        unit_id: department.unit_id.id,
      };

      try {
        await CrudService.updateItem(
          `departments/${department.id}`,
          newDepartment
        );
        navigate("/works/departments", {
          state: { value: true, text: t("updateSuccessfully") },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        console.error(err);
      }
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbTitle={`${department.title}`} />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("updateDepartment")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        label={t("title")}
                        placeholder="Alec"
                        name="title"
                        value={department.title}
                        onChange={changeHandler}
                        error={error.title}
                      />
                      {error.title && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                  <MDBox display="flex" flexDirection="column">
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={units}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={department.unit_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={(event, newValue) => {
                          setDepartment({ ...department, unit_id: newValue });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("units")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.unit_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>
                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/works/departments", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditDepartment;
