/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import FormField from "layouts/applications/wizard/components/FormField";
// import console from "node:console";
import i18n from "i18n";

const FilterResearch = ({ setFilterUrl }) => {
  const { t } = i18n;

  const [research, setResearch] = useState({
    title: "",
  });

  const changeHandler = (e) => {
    setResearch({
      ...research,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let str = "";
      const values = {
        title: research?.title,
      };
      const keys = Object.keys(values);
      keys.forEach((element) => {
        if (values[element] && values[element] !== []) {
          if (typeof values[element] === "object") {
            str += `&filter[${element}]=${values[element].id}`;
          } else {
            str += `&filter[${element}][_contains]=${values[element]}`;
          }
        }
      });
      setFilterUrl(str);
    } catch (err) {
      console.log({ error: true, textError: err.message });
      return null;
    }
  };

  return (
    <MDBox
      component="form"
      method="POST"
      onSubmit={submitHandler}
      encType="multipart/form-data"
      px={5}
    >
      <MDBox display="flex" flexDirection="column" px={3} my={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t("title")}
              placeholder="Alec"
              name="title"
              value={research.title}
              onChange={changeHandler}
            />
          </Grid>
        </Grid>
        <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="dark" size="small" type="submit">
            {t("search")}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default FilterResearch;
