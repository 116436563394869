/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import FormField from "layouts/applications/wizard/components/FormField";
// import console from "node:console";
import i18n from "i18n";

const FilterWork = ({ setFilterUrl }) => {
  const { t } = i18n;

  const [orderType, setOrderType] = useState({
    title: "",
  });

  const changeHandler = (e) => {
    setOrderType({
      ...orderType,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let str = "";
      console.log(orderType);
      if (orderType.title !== "")
        str += `&filter[_or][1][program_id][title][_contains]=${orderType.title}&filter[_or][2][research][title][_contains]=${orderType.title}&filter[_or][3][user_id][first_name][_contains]=${orderType.title}&filter[_or][4][co_user_id][first_name][_contains]=${orderType.title}&filter[_or][5][task_id][title][_contains]=${orderType.title}`;
      // &filter[_or][6][commissioning_date][_contains]=${orderType.title}&filter[_or][7][review_date][_contains]=${orderType.title}&filter[_or][8][accreditation_date][_contains]=${orderType.title}&filter[_or][9][due_date][_contains]=${orderType.title}&filter[_or][10][completion_date][_contains]=${orderType.title}&filter[_or][11][target_date][_contains]=${orderType.title}`;
      setFilterUrl(str);
    } catch (err) {
      console.log({ error: true, textError: err.message });
      return null;
    }
  };

  return (
    <MDBox
      component="form"
      method="POST"
      onSubmit={submitHandler}
      encType="multipart/form-data"
      px={5}
    >
      <MDBox display="flex" flexDirection="column" px={3} my={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              label={t("title")}
              placeholder="Alec"
              name="title"
              value={orderType.title}
              onChange={changeHandler}
            />
          </Grid>
        </Grid>
        <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="dark" size="small" type="submit">
            {t("search")}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default FilterWork;
