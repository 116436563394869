import AuthService from "services/auth-service";

export const getPermissions = async (id) => {
  try {
    const res = await AuthService.getUserPermissions(id);
    let x = [];
    res.data.map((r) => x.push(r.permissions));
    const permissions = x.flat(1);
    let jsonPermissions = [];
    permissions.map((permission) => {
      let action = permission.action;
      let subject;
      if (action === "read") action = "view";
      if (action === "update") action = "edit";
      const namePermission = permission.collection.split("_");
      if (
        namePermission.length === 2 &&
        namePermission[0] === "directus"
        //&& id === "bc2dbab4-800b-43de-a5cc-48559700caed"
      ) {
        subject = namePermission[1];
      } else {
        subject = permission.collection;
      }
      if (
        jsonPermissions.filter(
          (x) => x.subject === subject && x.action === action
        ).length === 0
      )
        jsonPermissions = [...jsonPermissions, { action, subject }];
    });
    console.log(jsonPermissions);
    return jsonPermissions;
  } catch (err) {
    console.error(err);
    return null;
  }
};
