/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete } from "@mui/material";

import { AuthContext } from "context";
// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";

// Trakib examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate, useParams } from "react-router-dom";
import MDDatePicker from "components/MDDatePicker";

import CrudService from "services/cruds-service";
import i18n from "i18n";

const EditWork = () => {
  //if administrator (isCurrentUserAdmin() === true) do and show and enable everything

  const { id } = useParams();
  const { t } = i18n;
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  // let  statuses = [
  //   { id: "in_progress", title: t("in_progress") },
  //   { id: "in_review", title: t("in_review") },
  //   { id: "in_approval", title: t("in_approval") },
  //   { id: "done", title: t("done") },
  //   { id: "canceled", title: t("canceled") },
  // ];
  const [statuses, setStatuses] = useState([
    { id: "in_progress", title: t("in_progress") },
    { id: "in_review", title: t("in_review") },
    { id: "in_approval", title: t("in_approval") },
    { id: "done", title: t("done") },
    { id: "canceled", title: t("canceled") },
  ]);
  const [jobtitles, setJobTitles] = useState([]);
  const [units, setUnits] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [researches, setResearches] = useState([]);
  const [users, setUsers] = useState([]);
  const [cousers, setCoUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [work, setWork] = useState({
    work_status: null,
    unit_id: null,
    job_title_id: null,
    user_id: null,
    program_id: null,
    research: null,
    unit_id: null,
    department_id: null,
    co_job_title_id: null,
    co_user_id: null,
    task_id: null,
    commissioning_date: null,
    review_date: null,
    accreditation_date: null,
    due_date: null,
    completion_date: null,
    target_date: null,
    other_notes: null,
    notes: null,
  });

  const [dValue, setDValue] = useState({
    work_status: true,
    unit_id: true,
    job_title_id: true,
    user_id: true,
    program_id: true,
    research: true,
    unit_id: true,
    department_id: true,
    co_job_title_id: true,
    co_user_id: true,
    task_id: true,
    commissioning_date: true,
    review_date: true,
    accreditation_date: true,
    due_date: true,
    completion_date: true,
    target_date: true,
    other_notes: true,
    notes: true,
  });

  const [error, setError] = useState({
    work_status: false,
    unit_id: false,
    job_title_id: false,
    user_id: false,
    co_job_title_id: false,
    co_user_id: false,
    program_id: false,
    research: false,
    unit_id: false,
    department_id: false,
    task_id: false,
    commissioning_date: false,
    review_date: false,
    accreditation_date: false,
    due_date: false,
    completion_date: false,
    target_date: false,
    other_notes: false,
    notes: false,
  });

  useEffect(() => {
    (async () => {
      try {
        const usersRes = await CrudService.getAllData(
          "items/job_title?fields=id,title"
        );
        setJobTitles(usersRes.data);
        const unitsRes = await CrudService.getAllData(
          "items/units?fields=id,title"
        );
        setUnits(unitsRes.data);
        const programsRes = await CrudService.getAllData(
          "items/programs?fields=id,title"
        );
        setPrograms(programsRes.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const response = await CrudService.getAllData(
          `items/works/${id}?fields=id,work_status,unit_id.id,unit_id.title,job_title_id.id,job_title_id.title,user_id.id,user_id.first_name,co_job_title_id.id,co_job_title_id.title,co_user_id.id,co_user_id.first_name,program_id.id,program_id.title,research.id,research.title,department_id.id,department_id.title,task_id.id,task_id.title,commissioning_date,review_date,accreditation_date,due_date,completion_date,target_date,notes,other_notes`
        );
        const getData = response.data;

        const workData = {
          id: getData.id,
          work_status: statuses.filter((s) => s.id === getData.work_status)[0],
          unit_id: getData.unit_id,
          job_title_id: getData.job_title_id,
          user_id: getData.user_id,
          program_id: getData.program_id,
          research: getData.research,
          unit_id: getData.unit_id,
          department_id: getData.department_id,
          co_job_title_id: getData.co_job_title_id,
          co_user_id: getData.co_user_id,
          task_id: getData.task_id,
          commissioning_date: getData.commissioning_date,
          review_date: getData.review_date,
          accreditation_date: getData.accreditation_date,
          due_date: getData.due_date,
          completion_date: getData.completion_date,
          target_date: getData.target_date,
          other_notes: getData.other_notes,
          notes: getData.notes,
        };
        setWork(workData);

        const user = await authContext.getCurrentUser();

        if (getData.co_user_id.id === user.id) {
          setDValue({
            work_status: false,
            unit_id: false,
            job_title_id: false,
            user_id: false,
            program_id: false,
            research: false,
            unit_id: false,
            department_id: false,
            co_job_title_id: false,
            co_user_id: false,
            task_id: false,
            commissioning_date: false,
            review_date: false,
            accreditation_date: false,
            due_date: false,
            completion_date: false,
            target_date: false,
            other_notes: true,
            notes: false,
          });
        } else {
          let newData = {
            work_status: true,
            unit_id: true,
            job_title_id: true,
            user_id: true,
            program_id: true,
            research: true,
            unit_id: true,
            department_id: true,
            co_job_title_id: true,
            co_user_id: true,
            task_id: true,
            commissioning_date: true,
            review_date: true,
            accreditation_date: true,
            due_date: true,
            completion_date: false,
            target_date: true,
            other_notes: false,
            notes: true,
          };
          if (getData.work_status === "in_progress") {
            newData.work_status = false;
            setStatuses([
              { id: "in_progress", title: t("in_progress") },
              { id: "in_review", title: t("in_review") },
            ]);
            setDValue(newData);
          }
        }

        const userRes = await CrudService.getAllData(
          `users?fields=id,first_name&filter[job_titles][job_title_id][_eq]=${getData.job_title_id.id}`
        );
        setUsers(userRes.data);
        const taskRes = await CrudService.getAllData(
          `items/tasks?fields=id,title&filter[jobtitles][job_title_id][_eq]=${getData.job_title_id.id}`
        );
        setTasks(taskRes.data);
        const coUserRes = await CrudService.getAllData(
          `users?fields=id,first_name&filter[job_titles][job_title_id][_eq]=${getData.co_job_title_id.id}`
        );
        setCoUsers(coUserRes.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [id]);

  const submitHandler = async (e) => {
    e.preventDefault();

    // if (work.work_type_id === "") {
    //   setError({
    //     work_type_id: true,
    //     jobtitle_id: false,
    //     user_id: false,
    //     details: false,
    //     textError: t("required"),
    //   });
    //   return;
    // }

    // if (!work.jobtitle_id) {
    //   setError({
    //     work_type_id: false,
    //     details: false,
    //     title: false,
    //     jobtitle_id: true,
    //     user_id: false,
    //     textError: t("required"),
    //   });
    //   return;
    // }

    // if (!work.user_id) {
    //   setError({
    //     work_type_id: false,
    //     details: false,
    //     jobtitle_id: false,
    //     user_id: true,
    //     textError: t("required"),
    //   });
    //   return;
    // }

    try {
      const newWork = {
        work_status: work.work_status.id,
        unit_id: work.unit_id.id,
        job_title_id: work.job_title_id?.id,
        user_id: work.user_id?.id,
        program_id: work.program_id.id,
        research: work.research.id,
        unit_id: work.unit_id.id,
        department_id: work.department_id.id,
        co_job_title_id: work.co_job_title_id.id,
        co_user_id: work.co_user_id.id,
        task_id: work.task_id?.task_id?.id,
        commissioning_date: work.commissioning_date,
        review_date: work.review_date,
        accreditation_date: work.accreditation_date,
        due_date: work.due_date,
        completion_date: work.completion_date,
        target_date: work.target_date,
        other_notes: work.other_notes,
        notes: work.notes,
      };
      try {
        await CrudService.updateItem(`items/works/${work.id}`, newWork);
        navigate("/works", {
          state: { value: true, text: t("updateSuccessfully") },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        console.error(err);
      }
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbTitle={`${work.id}`} />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("updateWork")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <MDBox display="flex" flexDirection="column">
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={statuses}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={work.work_status}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={async (event, newValue) => {
                          setWork({
                            ...work,
                            work_status: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            disabled={dValue.work_status}
                            label={t("status")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.work_status && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={units}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={work.unit_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const departmentsRes = await CrudService.getAllData(
                              `items/departments?fields=id,title,unit_id&filter[unit_id]=${newValue.id}`
                            );
                            setDepartments(departmentsRes.data);
                          }
                          setWork({
                            ...work,
                            unit_id: newValue,
                            department_id: "",
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            disabled={dValue.unit_id}
                            label={t("units")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.unit_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {work.unit_id && (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        marginTop="2rem"
                      >
                        <Autocomplete
                          defaultValue={null}
                          options={departments}
                          getOptionLabel={(option) => {
                            if (option.data) {
                              if (option.data.title) return option.data.title;
                            } else {
                              if (option) {
                                if (option.title) return option.title;
                              }
                            }
                            return "";
                          }}
                          value={work.department_id}
                          isOptionEqualToValue={(option, item) =>
                            option === item || option.id === item.id
                          }
                          onChange={(event, newValue) => {
                            setWork({
                              ...work,
                              department_id: newValue,
                            });
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              disabled={dValue.department_id}
                              label={t("departments")}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {error.department_id && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                            pl={4}
                          >
                            {error.textError}
                          </MDTypography>
                        )}
                      </MDBox>
                    )}
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={programs}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={work.program_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const researchesRes = await CrudService.getAllData(
                              `items/researches?fields=id,title,program_id&filter[program_id]=${newValue.id}`
                            );
                            setResearches(researchesRes.data);
                          }
                          setWork({
                            ...work,
                            program_id: newValue,
                            research: "",
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            disabled={dValue.program_id}
                            label={t("programs")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.program_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {work.program_id && (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        marginTop="2rem"
                      >
                        <Autocomplete
                          defaultValue={null}
                          options={researches}
                          getOptionLabel={(option) => {
                            if (option.data) {
                              if (option.data.title) return option.data.title;
                            } else {
                              if (option) {
                                if (option.title) return option.title;
                              }
                            }
                            return "";
                          }}
                          value={work.research}
                          isOptionEqualToValue={(option, item) =>
                            option === item || option.id === item.id
                          }
                          onChange={(event, newValue) => {
                            setWork({
                              ...work,
                              research: newValue,
                            });
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              disabled={dValue.research}
                              label={t("researches")}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {error.research && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                            pl={4}
                          >
                            {error.textError}
                          </MDTypography>
                        )}
                      </MDBox>
                    )}
                    {/*  */}
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={jobtitles}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={work.co_job_title_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const userRes = await CrudService.getAllData(
                              `users?fields=id,first_name&filter[job_titles][job_title_id][_eq]=${newValue.id}`
                            );
                            setCoUsers(userRes.data);
                          }
                          setWork({
                            ...work,
                            co_job_title_id: newValue,
                            co_user_id: "",
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            disabled={dValue.co_job_title_id}
                            label={t("cojobtitles")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.co_job_title_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {work.co_job_title_id && (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        marginTop="2rem"
                      >
                        <Autocomplete
                          defaultValue={null}
                          options={cousers}
                          getOptionLabel={(option) => {
                            if (option.data) {
                              if (option.data.first_name)
                                return option.data.first_name;
                            } else {
                              if (option) {
                                if (option.first_name) return option.first_name;
                              }
                            }
                            return "";
                          }}
                          value={work.co_user_id}
                          isOptionEqualToValue={(option, item) =>
                            option === item || option.id === item.id
                          }
                          onChange={(event, newValue) => {
                            console.log("dvdscedfcefcedcef");
                            setWork({
                              ...work,
                              co_user_id: newValue,
                            });
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              disabled={dValue.co_user_id}
                              label={t("users")}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {error.co_user_id && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                            pl={4}
                          >
                            {error.textError}
                          </MDTypography>
                        )}
                      </MDBox>
                    )}
                    {/*  */}
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={jobtitles}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={work.job_title_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const userRes = await CrudService.getAllData(
                              `users?fields=id,first_name&filter[job_titles][job_title_id][_eq]=${newValue.id}&filter[job_titles][status][_eq]=published`
                            );
                            setUsers(userRes.data);
                            const taskRes = await CrudService.getAllData(
                              `items/tasks?fields=id,title&filter[jobtitles][job_title_id][_eq]=${newValue.id}`
                            );
                            setTasks(taskRes.data);
                          }
                          setWork({
                            ...work,
                            job_title_id: newValue,
                            user_id: "",
                            task_id: "",
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            disabled={dValue.job_title_id}
                            label={t("oUser")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.job_title_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {work.job_title_id && (
                      <MDBox>
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          marginTop="2rem"
                        >
                          <Autocomplete
                            defaultValue={null}
                            options={users}
                            getOptionLabel={(option) => {
                              if (option.data) {
                                if (option.data.first_name)
                                  return option.data.first_name;
                              } else {
                                if (option) {
                                  if (option.first_name)
                                    return option.first_name;
                                }
                              }
                              return "";
                            }}
                            value={work.user_id}
                            isOptionEqualToValue={(option, item) =>
                              option === item || option.id === item.id
                            }
                            onChange={(event, newValue) => {
                              setWork({
                                ...work,
                                user_id: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <FormField
                                {...params}
                                disabled={dValue.user_id}
                                label={t("users")}
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                          {error.user_id && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                              pl={4}
                            >
                              {error.textError}
                            </MDTypography>
                          )}
                        </MDBox>
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          marginTop="2rem"
                        >
                          <Autocomplete
                            defaultValue={null}
                            options={tasks}
                            getOptionLabel={(option) => {
                              if (option.data) {
                                if (option.data.title) return option.data.title;
                              } else {
                                if (option) {
                                  if (option.title) return option.title;
                                }
                              }
                              return "";
                            }}
                            value={work.task_id}
                            isOptionEqualToValue={(option, item) =>
                              option === item || option.id === item.id
                            }
                            onChange={(event, newValue) => {
                              setWork({
                                ...work,
                                task_id: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <FormField
                                {...params}
                                disabled={dValue.task_id}
                                label={t("tasks")}
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                          {error.task_id && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              fontWeight="light"
                              pl={4}
                            >
                              {error.textError}
                            </MDTypography>
                          )}
                        </MDBox>
                      </MDBox>
                    )}
                    <Grid
                      container
                      spacing={3}
                      key={Math.random().toString()}
                      pt={3}
                    >
                      <Grid item xs={6}>
                        <MDDatePicker
                          onChange={(event, value) =>
                            setWork({
                              ...work,
                              commissioning_date: value,
                            })
                          }
                          value={work.commissioning_date}
                          name="commissioning_date"
                          input={{
                            placeholder: t("commissioning_date"),
                            fullWidth: true,
                            disabled: dValue.commissioning_date,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MDDatePicker
                          disabled={dValue.target_date}
                          onChange={(event, value) =>
                            setWork({
                              ...work,
                              target_date: value,
                            })
                          }
                          value={work.target_date}
                          name="target_date"
                          input={{
                            placeholder: t("target_date"),
                            fullWidth: true,
                            disabled: dValue.target_date,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MDDatePicker
                          disabled={dValue.review_date}
                          onChange={(event, value) =>
                            setWork({
                              ...work,
                              review_date: value,
                            })
                          }
                          value={work.review_date}
                          name="review_date"
                          input={{
                            placeholder: t("review_date"),
                            fullWidth: true,
                            disabled: dValue.review_date,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MDDatePicker
                          disabled={dValue.accreditation_date}
                          onChange={(event, value) =>
                            setWork({
                              ...work,
                              accreditation_date: value,
                            })
                          }
                          value={work.accreditation_date}
                          name="accreditation_date"
                          input={{
                            placeholder: t("accreditation_date"),
                            fullWidth: true,
                            disabled: dValue.accreditation_date,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MDDatePicker
                          disabled={dValue.due_date}
                          onChange={(event, value) =>
                            setWork({
                              ...work,
                              due_date: value,
                            })
                          }
                          value={work.due_date}
                          name="due_date"
                          input={{
                            placeholder: t("due_date"),
                            fullWidth: true,
                            disabled: dValue.due_date,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MDDatePicker
                          disabled={dValue.completion_date}
                          onChange={(event, value) =>
                            setWork({
                              ...work,
                              completion_date: value,
                            })
                          }
                          value={work.completion_date}
                          name="completion_date"
                          input={{
                            placeholder: t("completion_date"),
                            fullWidth: true,
                            disabled: dValue.completion_date,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <MDBox pt={3}>
                      <FormField
                        pt={1}
                        multiline
                        variant="outlined"
                        rows={5}
                        disabled={dValue.notes}
                        label={t("notes")}
                        placeholder={t("notes")}
                        name="notes"
                        value={work.notes}
                        onChange={(e) =>
                          setWork({
                            ...work,
                            notes: e.target.value,
                          })
                        }
                      />
                    </MDBox>
                    <MDBox pt={3}>
                      <FormField
                        multiline
                        variant="outlined"
                        rows={5}
                        disabled={dValue.other_notes}
                        label={t("other_notes")}
                        placeholder={t("other_notes")}
                        name="other_notes"
                        value={work.other_notes}
                        onChange={(e) =>
                          setWork({
                            ...work,
                            other_notes: e.target.value,
                          })
                        }
                      />
                    </MDBox>
                  </MDBox>

                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/works", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditWork;
