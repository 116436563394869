/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Trakib components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Trakib examples
import DefaultLayout from "examples/LayoutContainers//DefaultLayout";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { Tooltip, IconButton } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import CrudService from "services/cruds-service";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "context";

import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import { Can } from "Can";
import { subject } from "@casl/ability";
import i18n from "i18n";

import FilterResearch from "./filter-research";

function Researches() {
  let { state } = useLocation();
  const { t } = i18n;
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [sortVal, setSortVal] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [research, setResearchs] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [limitChange, setLimitChange] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterUrl, setFilterUrl] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });
  const reqUrl = `items/researches?fields=*,program_id.title,program_id.id,ranking_id.title,ranking_id.id&page=${page}`;
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      setIsAdmin(await authContext.isCurrentUserAdmin());
    })();
  }, []);

  const getResearchs = async (filUrl) => {
    const response = await CrudService.getData(`${reqUrl}${filUrl}`);
    setResearchs(response.data);
    setMeta(response.meta);
  };

  useEffect(() => {
    if (limitChange) {
      setLimitChange(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    if (filterData) {
      setFilterData(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    let sortUrl = "";
    if (sortVal) {
      sortUrl = `&sort=${sortVal.desc ? "-" : "+"}${sortVal.id}`;
    }
    getResearchs(`${filterUrl}${sortUrl}`);
  }, [page, limitChange, sortVal, filterData]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    else if (filterUrl !== "") setFilterData(true); // to do any action to fire useEffect which fire when filterData changed to do getResearchs function and aproove that sort value exist
  }, [filterUrl]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickAddHandler = () => {
    navigate("/programs/researches/add");
  };

  const clickEditHandler = (id) => {
    navigate(`/programs/researches/update/${id}`);
  };

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      return {
        id: row.id,
        title: row.title,
        program: row.program_id?.title,
        ranking: row.ranking_id?.title,
      };
    });
    return updatedInfo;
  };

  useEffect(() => {
    setTableData(getRows(research));
  }, [research]);

  const dataTableData = {
    columns: [
      { Header: t("title"), accessor: "title", width: "15%" },
      {
        Header: t("programs"),
        accessor: "program",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: t("research_rankings"),
        accessor: "ranking",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: t("actions"),
        disableSortBy: true,
        accessor: "",
        Cell: (info) => {
          return (
            <MDBox display="flex" alignItems="center">
              {isAdmin ? (
                <>
                  <Tooltip
                    title={t("updateResearch")}
                    onClick={() => clickEditHandler(info.cell.row.original.id)}
                  >
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Can
                    I="edit"
                    this={subject("researches", info.cell.row.original)}
                  >
                    <Tooltip
                      title={"updateResearch"}
                      onClick={() =>
                        clickEditHandler(info.cell.row.original.id)
                      }
                    >
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Can>
                </>
              )}
            </MDBox>
          );
        },
      },
      ,
    ],
    rows: tableData,
  };

  return (
    <DefaultLayout>
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pt={3} pb={3} mb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              {t("researches")}
            </MDTypography>
            <MDBox display="flex">
              {(ability.can("create", "researches") || isAdmin) && (
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={clickAddHandler}
                  mx={1}
                >
                  + {t("addResearch")}
                </MDButton>
              )}
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                type="submit"
                onClick={() => setShowFilter(!showFilter)}
                sx={{ mx: 1 }}
              >
                {t("filter")}
              </MDButton>
            </MDBox>
          </MDBox>
          {showFilter && (
            <FilterResearch
              setFilterUrl={setFilterUrl}
              // filterUrl={defaultFilterUrl}
            />
          )}
          <DataTable
            setLimitChange={setLimitChange}
            table={dataTableData}
            meta={meta}
            setPage={setPage}
            setSortVal={setSortVal}
            pageNum={page}
            isSorted={false}
          />
        </Card>
      </MDBox>
    </DefaultLayout>
  );
}

export default Researches;
