/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete } from "@mui/material";
import Chip from "@mui/material/Chip";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";

// Trakib examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate, useParams } from "react-router-dom";

import CrudService from "services/cruds-service";
import i18n from "i18n";

const EditUser = () => {
  const { id } = useParams();
  const { t } = i18n;
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [fileState, setFileState] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [jobTitleValue, setJobTiltleValue] = useState([]);
  const [policyValue, setPolicyValue] = useState([]);
  const [createdPolicies, setCreatedPolicies] = useState([]);
  const [removedPolicies, setRemovedPolicies] = useState([]);

  const baseUrl = process.env.REACT_APP_API_URL;

  const [user, setUser] = useState({
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    role: null,
    job_titles: [],
    policies: [],
  });

  const [error, setError] = useState({
    first_name: false,
    last_name: false,
    email: false,
    role: false,
    error: false,
    job_titles: false,
    policies: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await CrudService.getAllData("roles?fields=name,id");
        setRoles(data);
        const jobTitleRes = await CrudService.getAllData(
          "items/job_title?fields=id,title"
        );
        setJobTitles(jobTitleRes.data);
        const policiesRes = await CrudService.getAllData(
          "policies?fields=id,name"
        );
        setPolicies(policiesRes.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const response = await CrudService.getAllData(
          `users/${id}?fields=*,role.name,role.id,job_titles.id,job_titles.job_title_id,job_titles.job_title_id.id,job_titles.job_title_id.title,job_titles.status,policies.id,policies.policy.id,policies.policy.name`
        );
        const getData = response.data;
        const userData = {
          id: getData.id,
          first_name: getData.first_name,
          last_name: getData.last_name,
          email: getData.email,
          role: getData.role,
          job_titles: getData.job_titles,
          policies: getData.policies,
        };
        setUser(userData);
        setImageUrl(
          getData.avatar ? `${baseUrl}assets/${getData.avatar}` : null
        );
      } catch (err) {
        console.error(err);
      }
    })();
  }, [id]);

  const getUserJobTitle = () => {
    const jobTitlesId = [];
    user.job_titles.forEach((jobTitle) => {
      jobTitlesId.push(jobTitle.job_title_id);
    });
    console.log(jobTitlesId);

    setJobTiltleValue(jobTitlesId);
  };

  const getUserPolicy = () => {
    const policiesId = [];
    user.policies.forEach((policy) => {
      policiesId.push(policy.policy);
    });
    console.log(policiesId);

    setPolicyValue(policiesId);
  };

  useEffect(() => {
    if (user.job_titles.length > 0 && jobTitles.length > 0) {
      getUserJobTitle();
    }
  }, [jobTitles, user]);

  useEffect(() => {
    if (user.policies.length > 0 && policies.length > 0) {
      getUserPolicy();
    }
  }, [policies, user]);

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const changeImageHandler = (e) => {
    const formData = new FormData();
    formData.append("attachment", e.target.files[0]);
    setFileState(formData);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(removedPolicies, createdPolicies);

    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!user.first_name || user.first_name.trim().length === 0) {
      setError({
        email: false,
        role: false,
        first_name: true,
        last_name: false,
        job_titles: false,
        textError: t("required"),
      });
      return;
    }

    if (!user.last_name || user.last_name.trim().length === 0) {
      setError({
        email: false,
        role: false,
        first_name: false,
        job_titles: false,
        last_name: true,
        textError: t("required"),
      });
      return;
    }

    if (
      !user.email ||
      user.email.trim().length === 0 ||
      !user.email.trim().match(mailFormat)
    ) {
      setError({
        role: false,
        first_name: false,
        job_titles: false,
        last_name: false,
        email: true,
        textError: t("required"),
      });
      return;
    }
    // if (!user.role) {
    //   setError({
    //     first_name: false,
    //     last_name: false,
    //     email: false,
    //     job_titles: false,
    //     password: false,
    //     confirm: false,
    //     role: true,
    //     textError: t("required"),
    //   });
    //   return;
    // }

    if (user.job_titles.length === 0) {
      setError({
        first_name: false,
        last_name: false,
        email: false,
        job_titles: true,
        password: false,
        confirm: false,
        role: false,
        textError: t("required"),
      });
      return;
    }

    try {
      // let y={policies:[{create:{user: "6f26cc36-2b8c-4535-b6bf-c4148fdbb5a7", policy: {id: "e82f0b70-c473-40f3-a50f-488cf42cf37b"}}], update: [], delete: ["d413fa8a-5d0e-4d22-9398-1cc5f825cfcf"]}}
      let cp = [];
      createdPolicies.forEach((element) => {
        cp.push({ user: user.id, policy: { id: element } });
      });
      let rp = [];
      removedPolicies.forEach((element) => {
        rp.push({ user: user.id, policy: { id: element } });
      });

      let data = fileState
        ? await CrudService.addUserAvatar(fileState, user.id).id
        : user.avatar;

      const newUser = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        avatar: data,
        role: user.role.id,
        policies: { create: cp, update: [], delete: removedPolicies },
      };

      try {
        await CrudService.updateItem(`users/${user.id}`, newUser);
        navigate("/userManagement/users", {
          state: { value: true, text: t("updateSuccessfully") },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        console.error(err);
      }
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadcrumbTitle={`${user.first_name} ${user.last_name}`}
      />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("updateUser")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label={t("firstName")}
                        placeholder="Alec"
                        name="first_name"
                        value={user.first_name}
                        onChange={changeHandler}
                        error={error.first_name}
                      />
                      {error.first_name && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label={t("lastName")}
                        placeholder="Alec"
                        name="last_name"
                        value={user.last_name}
                        onChange={changeHandler}
                        error={error.last_name}
                      />
                      {error.last_name && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label={t("email")}
                        placeholder="example@email.com"
                        inputProps={{ type: "email" }}
                        name="email"
                        value={user.email}
                        onChange={changeHandler}
                        error={error.email}
                      />
                      {error.email && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                  <MDBox display="flex" flexDirection="column">
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={roles}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.name) return option.data.name;
                          } else {
                            if (option) {
                              if (option.name) return option.name;
                            }
                          }
                          return "";
                        }}
                        value={user.role}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={(event, newValue) => {
                          setUser({ ...user, role: newValue });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("role")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.role && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        multiple
                        defaultValue={null}
                        options={jobTitles}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={jobTitleValue}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={async (event, newValue, reason, detail) => {
                          if (reason === "removeOption") {
                            let removedItem = user.job_titles.find(
                              (orgItem) =>
                                orgItem.job_title_id.id === detail.option.id
                            );
                            console.log(detail.option.id, removedItem);

                            let status;
                            removedItem.status === "deleted"
                              ? (status = "published")
                              : (status = "deleted");
                            await CrudService.updateItem(
                              `items/users_job_title/${removedItem.id}`,
                              { status: status }
                            );
                            // setJobTiltleValue(jobTitleValue);
                            // getUserJobTitle();
                          } else if (reason === "selectOption")
                            await CrudService.createItem(
                              `items/users_job_title`,
                              {
                                job_title_id: detail.option.id,
                                user_id: id,
                              }
                            );
                          setJobTiltleValue(newValue);
                        }}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            let x = !user.job_titles.filter(
                              (x) =>
                                x.job_title_id.id === option.id &&
                                x.status === "deleted"
                            )[0];
                            return (
                              <Chip
                                key={key}
                                label={option.title}
                                {...tagProps}
                                style={{
                                  background: user.job_titles.filter(
                                    (x) =>
                                      x.job_title_id.id === option.id &&
                                      x.status === "deleted"
                                  )[0]
                                    ? "red"
                                    : "green",
                                }}
                              />
                            );
                          })
                        }
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("job_title")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.role && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {/*  */}
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        multiple
                        defaultValue={null}
                        options={policies}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.name) return option.data.name;
                          } else {
                            if (option) {
                              if (option.name) return option.name;
                            }
                          }
                          return "";
                        }}
                        value={policyValue}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        // onChange={(event, newValue) => {
                        //   setUser({ ...user, policies: newValue });
                        // }}
                        onChange={async (event, newValue, reason, detail) => {
                          if (reason === "removeOption") {
                            let c = removedPolicies;
                            let removedItem = user.policies.find(
                              (orgItem) =>
                                orgItem.policy.id === detail.option.id
                            );
                            c.push(removedItem.id);
                            setRemovedPolicies(c);
                          } else if (reason === "selectOption") {
                            let c = createdPolicies;
                            c.push(detail.option.id);
                            setCreatedPolicies(c);
                          }
                          setPolicyValue(newValue);
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("policies")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.policies && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {/*  */}
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDBox mt={2} display="flex" flexDirection="column">
                        <InputLabel id="demo-simple-select-label">
                          {t("profileImage")}
                        </InputLabel>
                        <MDInput
                          type="file"
                          name="attachment"
                          onChange={changeImageHandler}
                          id="file-input"
                          accept="image/*"
                          sx={{ mt: "16px" }}
                        ></MDInput>
                      </MDBox>

                      {imageUrl && (
                        <MDBox ml={4} mt={2}>
                          <MDAvatar
                            src={imageUrl}
                            alt="profile-image"
                            size="xxl"
                            shadow="sm"
                          />
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/userManagement/users", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditUser;
