/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Trakib components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ProductCellWithoutImage({ array }) {
  return (
    <MDBox alignItems="center">
      {array.map((x) => (
        <MDBox alignItems="center" key={Math.random().toString()}>
          <MDTypography variant="button" fontWeight="medium">
            {x}
          </MDTypography>
        </MDBox>
      ))}
    </MDBox>
  );
}

// Typechecking props for the ProductCellWithoutImage
ProductCellWithoutImage.propTypes = {
  array: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProductCellWithoutImage;
