/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete, Checkbox } from "@mui/material";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Trakib examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate, useParams } from "react-router-dom";

import CrudService from "services/cruds-service";
import i18n from "i18n";

const EditNotification = () => {
  const { id } = useParams();
  const { t } = i18n;
  const navigate = useNavigate();
  const [job_titles, setJobTitles] = useState([]);
  const [users, setUsers] = useState([]);
  const [inputs, setInputs] = useState([
    {
      key: "",
      value: "",
    },
  ]);

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        key: "",
        value: "",
      },
    ]);
  };

  const handleChange = (name, index, v) => {
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = v;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const [notification, setNotification] = useState({
    id: null,
    user_id: null,
    job_title_id: null,
    title: "",
    details: "",
    all: false,
  });

  const [error, setError] = useState({
    user_id: false,
    job_title_id: false,
    error: false,
    title: false,
    details: false,
    all: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await CrudService.getAllData(
          "items/job_title?fields=title,id"
        );
        setJobTitles(data);
        const usersRes = await CrudService.getAllData(
          `users?fields=id,first_name`
        );
        setUsers(usersRes.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const response = await CrudService.getAllData(
          `items/notifications/${id}?fields=id,title,details,all,job_title_id.id,job_title_id.title,user_id.first_name,user_id.id`
        );
        const getData = response.data;
        const notificationData = {
          id: getData.id,
          job_title_id: getData.job_title_id,
          user_id: getData.user_id,
          details: getData.details,
          title: getData.title,
          all: getData.all,
        };
        console.log(notificationData);

        setNotification(notificationData);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [id]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const newNotification = {
        title: notification.title,
        job_title_id: notification.job_title_id?.id,
        user_id: notification.user_id?.id,
        details: notification.details,
        all: notification.all,
      };

      try {
        await CrudService.updateItem(
          `items/notifications/${notification.id}`,
          newNotification
        );
        navigate("/system/notifications", {
          state: { value: true, text: t("updateSuccessfully") },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        console.error(err);
      }
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbTitle={`${notification.id}`} />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("updateNotification")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <MDBox display="flex" flexDirection="column">
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={job_titles}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={notification.job_title_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={async (event, newValue) => {
                          setNotification({
                            ...notification,
                            job_title_id: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("job_title")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.job_title_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={users}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.first_name)
                              return option.data.first_name;
                          } else {
                            if (option) {
                              console.log(option);
                              if (option.first_name) return option.first_name;
                            }
                          }
                          return "";
                        }}
                        value={notification.user_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={(event, newValue) => {
                          setNotification({
                            ...notification,
                            user_id: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("users")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.user_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <FormField
                      variant="outlined"
                      label={t("title")}
                      placeholder={t("title")}
                      name="title"
                      value={notification.title}
                      onChange={(e) => {
                        setNotification({
                          ...notification,
                          title: e.target.value,
                        });
                      }}
                    />
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <FormField
                      multiline
                      variant="outlined"
                      rows={5}
                      label={t("details")}
                      placeholder={t("details")}
                      name="details"
                      value={notification.details}
                      onChange={(e) => {
                        setNotification({
                          ...notification,
                          details: e.target.value,
                        });
                      }}
                    />
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <Checkbox
                      checked={notification.all}
                      name="all"
                      onChange={(e) => {
                        setNotification({
                          ...notification,
                          all: e.target.checked,
                        });
                      }}
                    />
                  </MDBox>
                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/system/notifications", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditNotification;
