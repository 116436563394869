/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete } from "@mui/material";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";

// Trakib examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate } from "react-router-dom";

import CrudService from "services/cruds-service";

import i18n from "i18n";

const AddResearch = () => {
  const navigate = useNavigate();

  const { t } = i18n;
  const [rankings, setRankings] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [research, setResearch] = useState({
    ranking_id: "",
    program_id: "",
    programs_rankings: "",
    title: "",
  });

  const [error, setError] = useState({
    title: false,
    ranking_id: false,
    program_id: false,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const programsRes = await CrudService.getAllData(
          "items/programs?fields=id,title"
        );
        setPrograms(programsRes.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  const changeHandler = (e) => {
    setResearch({
      ...research,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (research.title.trim().length === 0) {
      setError({
        ranking_id: false,
        program_id: false,
        title: true,
        textError: t("required"),
      });
      return;
    }

    if (research.ranking_id === "") {
      setError({
        title: false,
        ranking_id: true,
        program_id: false,
        textError: t("required"),
      });
      return;
    }

    if (research.program_id === "") {
      setError({
        title: false,
        ranking_id: false,
        program_id: true,
        textError: t("required"),
      });
      return;
    }

    const newResearch = {
      title: research.title,
      ranking_id: research.ranking_id.ranking_id.id,
      program_id: research.program_id.id,
      programs_rankings: research.ranking_id.id,
    };

    try {
      await CrudService.createItem("items/researches", newResearch);
      navigate("/programs/researches", {
        state: { value: true, text: t("createSuccessfully") },
      });
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("addResearch")}
                </MDTypography>
              </MDBox>
              {/* <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the research.
              </MDTypography> */}
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        label={t("title")}
                        placeholder=""
                        name="title"
                        value={research.title}
                        onChange={changeHandler}
                        error={error.title}
                      />
                      {error.title && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                  <MDBox display="flex" flexDirection="column">
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={programs}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={research.program_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const rankingsRes = await CrudService.getAllData(
                              `items/programs_rankings?fields=id,program_id.id,ranking_id.id,ranking_id.title&filter[program_id]=${newValue.id}`
                            );
                            setRankings(rankingsRes.data);
                          }
                          setResearch({
                            ...research,
                            program_id: newValue,
                            programs_rankings: "",
                            ranking_id: "",
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("programs")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.program_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {research.program_id && (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        marginTop="2rem"
                      >
                        <Autocomplete
                          defaultValue={null}
                          options={rankings}
                          getOptionLabel={(option) => {
                            if (option.data) {
                              if (option.data.ranking_id.title)
                                return option.data.ranking_id.title;
                            } else {
                              if (option) {
                                if (option.ranking_id.title)
                                  return option.ranking_id.title;
                              }
                            }
                            return "";
                          }}
                          value={research.ranking_id}
                          isOptionEqualToValue={(option, item) =>
                            option === item || option.id === item.id
                          }
                          onChange={(event, newValue) => {
                            setResearch({ ...research, ranking_id: newValue });
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              label={t("research_rankings")}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                        {error.ranking_id && (
                          <MDTypography
                            variant="caption"
                            color="error"
                            fontWeight="light"
                            pl={4}
                          >
                            {error.textError}
                          </MDTypography>
                        )}
                      </MDBox>
                    )}
                  </MDBox>

                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/programs/researches", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AddResearch;
