/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete } from "@mui/material";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import CrudService from "services/cruds-service";
import FormField from "layouts/applications/wizard/components/FormField";
// import console from "node:console";
import i18n from "i18n";

const FilterUser = ({ setFilterUrl, filterUrl }) => {
  const [users, setUsers] = useState([]);
  const [collections, setCollections] = useState([]);

  const actions = [
    { title: "create", id: "create" },
    { title: "update", id: "update" },
    { title: "delete", id: "delete" },
    { title: "login", id: "login" },
  ];

  const { t } = i18n;

  const [log, setLog] = useState({
    collection: null,
    user: null,
    action: null,
  });

  useEffect(() => {
    (async () => {
      try {
        const usersData = await CrudService.getAllData(
          `users?fields=id,first_name&limit=-1`
        );
        setUsers(usersData.data);
        const collectionsData =
          await CrudService.getAllData(`collections?limit=-1`);
        setCollections(collectionsData.data);
      } catch (err) {
        // console.error(err);
        return null;
      }
    })();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let str = "";

      const keys = Object.keys(log);
      keys.forEach((element) => {
        if (log[element] && log[element].length !== 0) {
          if (element === "collection") {
            str += `&filter[${element}][_contains]=${log[element].collection}`;
          } else if (typeof log[element] === "object") {
            str += `&filter[${element}]=${log[element].id}`;
          } else {
            str += `&filter[${element}][_contains]=${log[element]}`;
          }
        }
      });
      setFilterUrl(str);
    } catch (err) {
      console.log({ error: true, textError: err.message });
      return null;
    }
  };

  return (
    <MDBox
      component="form"
      method="POST"
      onSubmit={submitHandler}
      encType="multipart/form-data"
      px={5}
    >
      <MDBox display="flex" flexDirection="column" px={3} my={4}>
        <MDBox display="flex" flexDirection="column">
          <MDBox display="flex" flexDirection="column" marginTop="2rem">
            <Autocomplete
              defaultValue={null}
              options={actions}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.title) return option.data.title;
                } else {
                  if (option) {
                    if (option.title) return option.title;
                  }
                }
                return "";
              }}
              value={log.action}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              onChange={(event, newValue) => {
                setLog({ ...log, action: newValue });
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={t("action")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox>
          <MDBox display="flex" flexDirection="column" marginTop="2rem">
            <Autocomplete
              defaultValue={null}
              options={users}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.first_name) return option.data.first_name;
                } else {
                  if (option) {
                    if (option.first_name) return option.first_name;
                  }
                }
                return "";
              }}
              value={log.user}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              onChange={(event, newValue) => {
                setLog({ ...log, user: newValue });
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={t("users")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox>
          <MDBox display="flex" flexDirection="column" marginTop="2rem">
            <Autocomplete
              defaultValue={null}
              options={collections}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.collection) return option.data.collection;
                } else {
                  if (option) {
                    if (option.collection) return option.collection;
                  }
                }
                return "";
              }}
              value={log.collection}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              onChange={(event, newValue) => {
                setLog({ ...log, collection: newValue });
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={t("collections")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox>
        </MDBox>
        <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="dark" size="small" type="submit">
            {t("search")}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default FilterUser;
