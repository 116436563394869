/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Trakib components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Trakib examples
import DefaultLayout from "examples/LayoutContainers//DefaultLayout";
import DeleteIcon from "@mui/icons-material/Delete";

import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { Tooltip, IconButton, Checkbox } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import CrudService from "services/cruds-service";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "context";

import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import i18n from "i18n";

import FilterLink from "./filter-links";

function Links() {
  let { state } = useLocation();
  const { t } = i18n;

  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [sortVal, setSortVal] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [limitChange, setLimitChange] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterUrl, setFilterUrl] = useState("");
  const [filterData, setFilterData] = useState(false);

  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });
  const reqUrl = `items/links?fields=*,user_id.first_name,job_title_id.title,title,details,all&page=${page}`;

  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      setIsAdmin(await authContext.isCurrentUserAdmin());
    })();
  }, []);

  const getLinks = async (filUrl) => {
    const response = await CrudService.getData(`${reqUrl}${filUrl}`);
    setLinks(response.data);
    setMeta(response.meta);
  };

  useEffect(() => {
    if (limitChange) {
      setLimitChange(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    if (filterData) {
      setFilterData(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    let sortUrl = "";
    if (sortVal) {
      sortUrl = `&sort=${sortVal.desc ? "-" : "+"}${sortVal.id}`;
    }
    getLinks(`${filterUrl}${sortUrl}`);
  }, [page, limitChange, sortVal, filterData]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    else if (filterUrl !== "") setFilterData(true); // to do any action to fire useEffect which fire when filterData changed to do getLinks function and aproove that sort value exist
  }, [filterUrl]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickAddHandler = () => {
    navigate("/system/links/add");
  };

  const clickEditHandler = (id) => {
    navigate(`/system/links/update/${id}`);
  };

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      return {
        id: row.id,
        title: row.title,
        details: row.details,
        job_title: row.job_title_id?.title,
        user: row.user_id?.first_name,
        all: row.all,
      };
    });
    return updatedInfo;
  };

  useEffect(() => {
    setTableData(getRows(links));
  }, [links]);

  const clickDeleteHandler = async (e, id) => {
    try {
      if (!window.confirm(t("confirmDelete"))) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.removeItem(`items/links/${id}`);
        // the delete does not send a response
        // so I need to get again the items to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
        const response = await CrudService.getData(`${reqUrl}${filterUrl}`);
        setLinks(response.data);
        setNotification({
          value: true,
          text: t("deleteSuccessfully"),
        });
      }
    } catch (err) {
      // it sends error is the category is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].title,
        });
      }
      return null;
    }
  };

  const dataTableData = {
    columns: [
      {
        Header: t("job_title"),
        accessor: "job_title",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: t("user"),
        accessor: "user",
        width: "15%",
        disableSortBy: true,
      },
      { Header: t("title"), accessor: "title", width: "15%" },
      {
        Header: t("details"),
        accessor: "details",
        width: "15%",
        disableSortBy: true,
      },
      {
        Header: t("all"),
        accessor: "all",
        width: "15%",
        disableSortBy: true,
        Cell: (info) => {
          return (
            <Checkbox
              disabled
              checked={info.cell.row.original.all}
              name="all"
            />
          );
        },
      },
      {
        Header: t("actions"),
        disableSortBy: true,
        accessor: "",
        Cell: (info) => {
          return (
            <MDBox display="flex" alignItems="center">
              {/* {(ability.can("edit", "links") || isAdmin) && */}
              {/* info.cell.row.original.worksCount === 0 && ( */}
              <Tooltip
                title={t("updateLink")}
                onClick={(e) => clickEditHandler(info.cell.row.original.id)}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {/* // )} */}
              {(ability.can("delete", "items") || isAdmin) &&
                info.cell.row.original.worksCount === 0 && (
                  <Tooltip
                    title={t("deleteItem")}
                    onClick={(e) =>
                      clickDeleteHandler(e, info.cell.row.original.id)
                    }
                  >
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </MDBox>
          );
        },
      },
      ,
    ],
    rows: tableData,
  };

  return (
    <DefaultLayout>
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pt={3} pb={3} mb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              {t("links")}
            </MDTypography>
            <MDBox display="flex">
              {(ability.can("create", "links") || isAdmin) && (
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={clickAddHandler}
                  mx={1}
                >
                  + {t("addLink")}
                </MDButton>
              )}
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                type="submit"
                onClick={() => setShowFilter(!showFilter)}
                sx={{ mx: 1 }}
              >
                {t("filter")}
              </MDButton>
            </MDBox>
          </MDBox>
          {showFilter && (
            <FilterLink
              setFilterUrl={setFilterUrl}
              // filterUrl={defaultFilterUrl}
            />
          )}
          <DataTable
            setLimitChange={setLimitChange}
            table={dataTableData}
            meta={meta}
            setPage={setPage}
            setSortVal={setSortVal}
            pageNum={page}
            isSorted={false}
          />
        </Card>
      </MDBox>
    </DefaultLayout>
  );
}

export default Links;
