/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete, Checkbox } from "@mui/material";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Trakib examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate } from "react-router-dom";

import CrudService from "services/cruds-service";

import i18n from "i18n";

const AddLink = () => {
  const navigate = useNavigate();

  const { t } = i18n;
  const [job_titles, setJobTitles] = useState([]);
  const [users, setUsers] = useState([]);
  const [link, setLink] = useState({
    job_title_id: null,
    user_id: null,
    title: "",
    all: false,
    details: "",
  });

  const [error, setError] = useState({
    details: false,
    job_title_id: false,
    user_id: false,
    title: false,
    all: false,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const usersRes = await CrudService.getAllData(
          "items/job_title?fields=id,title"
        );
        setJobTitles(usersRes.data);
        const userRes = await CrudService.getAllData(
          `users?fields=id,first_name`
        );
        setUsers(userRes.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    const newLink = {
      title: link.title,
      details: link.details,
      job_title_id: link.job_title_id?.id,
      user_id: link.user_id?.id,
      all: link.all,
    };

    try {
      await CrudService.createItem("items/links", newLink);
      navigate("/system/links", {
        state: { value: true, text: t("createSuccessfully") },
      });
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("addLink")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <Autocomplete
                      defaultValue={null}
                      options={job_titles}
                      getOptionLabel={(option) => {
                        console.log(option);

                        if (option.data) {
                          if (option.data.title) return option.data.title;
                        } else {
                          if (option) {
                            if (option.title) return option.title;
                          }
                        }
                        return "";
                      }}
                      value={link.job_title_id}
                      isOptionEqualToValue={(option, item) =>
                        option === item || option.id === item.id
                      }
                      onChange={(event, newValue) => {
                        setLink({
                          ...link,
                          job_title_id: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label={t("job_title")}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    {error.job_title_id && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                        pl={4}
                      >
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <Autocomplete
                      defaultValue={null}
                      options={users}
                      getOptionLabel={(option) => {
                        if (option.data) {
                          if (option.data.first_name)
                            return option.data.first_name;
                        } else {
                          if (option) {
                            if (option.first_name) return option.first_name;
                          }
                        }
                        return "";
                      }}
                      value={link.user_id}
                      isOptionEqualToValue={(option, item) =>
                        option === item || option.id === item.id
                      }
                      onChange={(event, newValue) => {
                        setLink({
                          ...link,
                          user_id: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label={t("users")}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    {error.user_id && (
                      <MDTypography
                        variant="caption"
                        color="error"
                        fontWeight="light"
                        pl={4}
                      >
                        {error.textError}
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <FormField
                      variant="outlined"
                      label={t("title")}
                      placeholder={t("title")}
                      name="title"
                      value={link.title}
                      onChange={(e) => {
                        setLink({
                          ...link,
                          title: e.target.value,
                        });
                      }}
                    />
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <FormField
                      multiline
                      variant="outlined"
                      rows={5}
                      label={t("details")}
                      placeholder={t("details")}
                      name="details"
                      value={link.details}
                      onChange={(e) => {
                        setLink({
                          ...link,
                          details: e.target.value,
                        });
                      }}
                    />
                  </MDBox>
                  <MDBox display="flex" flexDirection="column" marginTop="2rem">
                    <Checkbox
                      checked={link.all}
                      name="all"
                      onChange={(e) => {
                        setLink({ ...link, all: e.target.checked });
                      }}
                    />
                  </MDBox>
                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/system/links", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AddLink;
