import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Trakib components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Trakib examples
import DefaultLayout from "examples/LayoutContainers//DefaultLayout";
import OrdersOverview from "components/OrdersOverview";
import TablePaginationDemo from "components/TablePagination";
import MDButton from "components/MDButton";

import CrudService from "services/cruds-service";
import { useLocation } from "react-router-dom";
import i18n from "i18n";

import FilterForm from "./components/FilterForm";

function activities() {
  let { state } = useLocation();
  const { t } = i18n;
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [sortVal, setSortVal] = useState(null);
  const [activities, setActivities] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [limitChange, setLimitChange] = useState(false);
  const [filterUrl, setFilterUrl] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });

  const reqUrl = `activity?fields=*,user.id,user.first_name,user.last_name&sort=-timestamp&page=${page}`;

  const getActivities = async (filUrl) => {
    const response = await CrudService.getData(`${reqUrl}${filUrl}`);
    setActivities(response.data);
    setMeta(response.meta);
  };

  useEffect(() => {
    if (limitChange) {
      setLimitChange(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    if (filterData) {
      setFilterData(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    let sortUrl = "";
    if (sortVal) {
      sortUrl = `&sort=${sortVal.desc ? "-" : "+"}${sortVal.id}`;
    }
    getActivities(`${filterUrl}${sortUrl}`);
  }, [page, limitChange, sortVal, filterData]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    else if (filterUrl !== "") setFilterData(true); // to do any action to fire useEffect which fire when filterData chenged to do getActivities function and aproove that sort value exist
  }, [filterUrl]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  return (
    <DefaultLayout>
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pt={3} pb={3} mb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              {t("activities")}
            </MDTypography>
            <MDBox display="flex">
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                type="submit"
                onClick={() => setShowFilter(!showFilter)}
                sx={{ mx: 1 }}
              >
                {t("filter")}
              </MDButton>
            </MDBox>
          </MDBox>
          {showFilter && <FilterForm setFilterUrl={setFilterUrl} />}

          <OrdersOverview title={t("activity")} data={activities} />
          {meta && (
            <TablePaginationDemo
              count={+meta.filter_count}
              setPageNum={setPage}
            />
          )}
        </Card>
      </MDBox>
    </DefaultLayout>
  );
}

export default activities;
