import EditUser from "cruds/user-management/edit-user";
import NewUser from "cruds/user-management/add-user";
import NewJobtitle from "cruds/jobtitles/add-jobtitle";
import EditJobtitle from "cruds/jobtitles/edit-jobtitle";
import NewProgram from "cruds/programs/add-program";
import EditProgram from "cruds/programs/edit-program";
import NewResearch from "cruds/researches/add-research";
import EditResearch from "cruds/researches/edit-research";
import NewDepartment from "cruds/departments/add-department";
import EditDepartment from "cruds/departments/edit-department";
import NewOrderType from "cruds/order-type/add-order-type";
import EditOrderType from "cruds/order-type/edit-order-type";
import NewOrder from "cruds/orders/add-orders";
import EditOrder from "cruds/orders/edit-orders";
import NewWork from "cruds/works/add-works";
import EditWork from "cruds/works/edit-works";
import AddItems from "cruds/settings/new-item";
import AddLink from "cruds/sys-admin/admin-links/add-links";
import EditLink from "cruds/sys-admin/admin-links/edit-links";
import AddNotification from "cruds/sys-admin/admin-notifications/add-notifications";
import EditNotification from "cruds/sys-admin/admin-notifications/edit-notifications";

/** 
  All of the routes for the Trakib are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

const crudRoutes = [
  {
    key: "add-user",
    route: "/userManagement/addUser/",
    component: <NewUser />,
    type: "users",
  },
  {
    key: "edit-user",
    route: "/userManagement/updateUser/:id",
    component: <EditUser />,
    type: "users",
  },
  {
    key: "add-jobtitle",
    route: "/jobTitles/add",
    component: <NewJobtitle />,
    type: "jobtitles",
  },
  {
    key: "edit-jobtitle",
    route: "/jobTitles/update/:id",
    component: <EditJobtitle />,
    type: "jobtitles",
  },
  {
    key: "add-standard",
    route: "/jobtitles/standards/add",
    component: <AddItems />,
    type: "standard",
  },
  {
    key: "add-task",
    route: "/jobtitles/tasks/add",
    component: <AddItems />,
    type: "task",
  },
  {
    key: "add-program",
    route: "/programs/add",
    component: <NewProgram />,
    type: "programs",
  },
  {
    key: "edit-program",
    route: "/programs/update/:id",
    component: <EditProgram />,
    type: "programs",
  },
  {
    key: "add-research",
    route: "/programs/researches/add",
    component: <NewResearch />,
    type: "researches",
  },
  {
    key: "edit-research",
    route: "/programs/researches/update/:id",
    component: <EditResearch />,
    type: "researches",
  },
  {
    key: "add-researchRankings",
    route: "/programs/research_rankings/add",
    component: <AddItems />,
    type: "research_rankings",
  },
  {
    key: "add-unit",
    route: "works/units/add",
    component: <AddItems />,
    type: "units",
  },
  {
    key: "add-department",
    route: "/works/departments/add",
    component: <NewDepartment />,
    type: "departmentes",
  },
  {
    key: "edit-department",
    route: "/works/departments/update/:id",
    component: <EditDepartment />,
    type: "departmentes",
  },
  {
    key: "add-order-type",
    route: "/orders/order_type/add",
    component: <NewOrderType />,
    type: "order_type",
  },
  {
    key: "edit-order-type",
    route: "/orders/order_type/update/:id",
    component: <EditOrderType />,
    type: "order_type",
  },
  {
    key: "add-order",
    route: "/orders/add",
    component: <NewOrder />,
    type: "order",
  },
  {
    key: "edit-order",
    route: "/orders/update/:id",
    component: <EditOrder />,
    type: "order",
  },
  {
    key: "add-work",
    route: "/works/add",
    component: <NewWork />,
    type: "work",
  },
  {
    key: "edit-work",
    route: "/works/update/:id",
    component: <EditWork />,
    type: "work",
  },
  {
    key: "add-link",
    route: "/system/links/add",
    component: <AddLink />,
    type: "link",
  },
  {
    key: "edit-link",
    route: "/system/links/update/:id",
    component: <EditLink />,
    type: "link",
  },
  {
    key: "add-notification",
    route: "/system/notifications/add",
    component: <AddNotification />,
    type: "notification",
  },
  {
    key: "edit-notification",
    route: "/system/notifications/update/:id",
    component: <EditNotification />,
    type: "notification",
  },
];

export default crudRoutes;
