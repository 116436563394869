/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete } from "@mui/material";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Trakib examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate } from "react-router-dom";

import CrudService from "services/cruds-service";

import i18n from "i18n";

const AddProgram = () => {
  const navigate = useNavigate();

  const { t } = i18n;
  const [rankings, setRankings] = useState([]);
  const [program, setProgram] = useState({
    title: "",
    description: "",
    rankings: [],
  });

  const [error, setError] = useState({
    title: false,
    description: false,
    rankings: false,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const rankingRes = await CrudService.getAllData(
          "items/research_rankings?fields=id,title"
        );
        setRankings(rankingRes.data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  const changeHandler = (e) => {
    setProgram({
      ...program,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (program.title.trim().length === 0) {
      setError({
        title: true,
        description: false,
        rankings: false,
        error: false,
        textError: t("required"),
      });
      return;
    }

    if (program.description.trim().length === 0) {
      setError({
        title: false,
        description: true,
        rankings: false,
        error: false,
        textError: t("required"),
      });
      return;
    }

    if (program.rankings.length === 0) {
      setError({
        title: false,
        description: false,
        rankings: true,
        error: false,
        textError: t("required"),
      });
      return;
    }

    const newProgram = {
      title: program.title,
      description: program.description,
      rankings: program.rankings,
    };

    let res = null;
    try {
      res = await CrudService.createProgram("items/programs", newProgram);
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
    console.log(res);
    //add avatar
    if (res) {
      try {
        navigate("/programs", {
          state: { value: true, text: t("createSuccessfully") },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        return null;
      }
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("addProgram")}
                </MDTypography>
              </MDBox>
              {/* <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the program.
              </MDTypography> */}
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        label={t("title")}
                        placeholder={t("title")}
                        name="title"
                        value={program.title}
                        onChange={changeHandler}
                        error={error.title}
                      />
                      {error.title && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        multiline
                        rows={5}
                        label={t("description")}
                        placeholder={t("description")}
                        name="description"
                        value={program.description}
                        onChange={changeHandler}
                        error={error.description}
                      />
                      {error.description && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                  <MDBox display="flex" flexDirection="column">
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        multiple
                        defaultValue={[]}
                        options={rankings}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={program.rankings}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={(event, newValue) => {
                          setProgram({ ...program, rankings: newValue });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("research_rankings")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.role && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>

                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/programs", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AddProgram;
