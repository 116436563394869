/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PropTypes from "prop-types";
import DashboardLayout from "../DashboardLayout";
import Footer from "examples/Footer";

function DefaultLayout({ children }) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {children}
      <Footer />
    </DashboardLayout>
  );
}

// Typechecking props for the DefaultLayout
DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
