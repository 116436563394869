/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Trakib components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Trakib examples
import DefaultLayout from "examples/LayoutContainers//DefaultLayout";
import DeleteIcon from "@mui/icons-material/Delete";

import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import ProductCellWithoutImage from "components/ProductCellWithoutImage";

import { Tooltip, IconButton } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import CrudService from "services/cruds-service";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "context";

import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import i18n from "i18n";

import FilterWork from "./filter-works";

function Works() {
  let { state } = useLocation();
  const { t } = i18n;

  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [sortVal, setSortVal] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState(null);
  const [works, setWorks] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [limitChange, setLimitChange] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterUrl, setFilterUrl] = useState("");
  const [filterData, setFilterData] = useState(false);

  // const handleChange = async (e, id) => {
  //   let payload = {
  //     [e.target.name]: e.target.checked,
  //   };
  //   await CrudService.updateItem(`items/works/${id}`, payload);
  //   let sortUrl = "";
  //   if (sortVal) {
  //     sortUrl = `&sort=${sortVal.desc ? "-" : "+"}${sortVal.id}`;
  //   }
  //   getWorks(`${filterUrl}${sortUrl}`);
  // };

  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });
  const reqUrl = `items/works?fields=*,work_status.id,work_status.title,unit_id.id,unit_id.title,job_title_id.id,job_title_id.title,user_id.id,user_id.first_name,co_job_title_id.id,co_job_title_id.title,co_user_id.id,co_user_id.first_name,user_id.first_name,program_id.id,program_id.title,research.id,research.title,department_id.id,department_id.title,task_id.id,task_id.title,commissioning_date,review_date,accreditation_date,due_date,completion_date,target_date&page=${page}`;
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      setIsAdmin(await authContext.isCurrentUserAdmin());
      setUserData(await authContext.getCurrentUser());
    })();
  }, []);

  const getWorks = async (filUrl) => {
    const response = await CrudService.getData(`${reqUrl}${filUrl}`);
    setWorks(response.data);
    setMeta(response.meta);
  };

  useEffect(() => {
    if (limitChange) {
      setLimitChange(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    if (filterData) {
      setFilterData(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    let sortUrl = "";
    if (sortVal) {
      sortUrl = `&sort=${sortVal.desc ? "-" : "+"}${sortVal.id}`;
    }
    getWorks(`${filterUrl}${sortUrl}`);
  }, [page, limitChange, sortVal, filterData]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    else if (filterUrl !== "") setFilterData(true); // to do any action to fire useEffect which fire when filterData changed to do getWorks function and aproove that sort value exist
  }, [filterUrl]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickAddHandler = () => {
    navigate("/works/add");
  };

  const clickEditHandler = (id) => {
    navigate(`/works/update/${id}`);
  };

  const getRows = (info) => {
    let updatedInfo = info.map((work) => {
      return {
        id: work.id,
        work_status: t(work.work_status),
        unit_id: work.unit_id?.title,
        job_title_id: work.job_title_id?.title,
        user_id: work.user_id?.first_name,
        program_id: work.program_id?.title,
        research: work.research?.title,
        unit_id: work.unit_id?.title,
        department_id: work.department_id?.title,
        co_job_title_id: work.co_job_title_id?.title,
        co_user_id: work.co_user_id?.first_name,
        task_id: work.task_id?.title,
        commissioning_date: work.commissioning_date,
        review_date: work.review_date,
        accreditation_date: work.accreditation_date,
        due_date: work.due_date,
        completion_date: work.completion_date,
        target_date: work.target_date,
      };
    });
    return updatedInfo;
  };

  useEffect(() => {
    userData && setTableData(getRows(works));
  }, [works, userData]);

  const clickDeleteHandler = async (e, id) => {
    try {
      if (!window.confirm(t("confirmDelete"))) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.removeItem(`items/works/${id}`);
        // the delete does not send a response
        // so I need to get again the items to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
        console.log(`${reqUrl}${filterUrl}`);
        const response = await CrudService.getData(`${reqUrl}${filterUrl}`);
        setWorks(response.data);
        setNotification({
          value: true,
          text: t("deleteSuccessfully"),
        });
      }
    } catch (err) {
      // it sends error is the category is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].title,
        });
      }
      return null;
    }
  };

  const dataTableData = {
    columns: [
      { Header: t("work_status"), accessor: "work_status", width: "15%" },
      {
        Header: t("commissioning_date"),
        accessor: "commissioning_date",
        width: "15%",
      },
      {
        Header: t("units"),
        accessor: "units",
        Cell: (info) => {
          return (
            <MDBox sx={{ cursor: "pointer" }}>
              <ProductCellWithoutImage
                array={[
                  info.cell.row.original.unit_id,
                  info.cell.row.original.department_id,
                ]}
              />
            </MDBox>
          );
        },
      },
      {
        Header: t("programs"),
        accessor: "programs",
        Cell: (info) => {
          return (
            <MDBox sx={{ cursor: "pointer" }}>
              <ProductCellWithoutImage
                array={[
                  info.cell.row.original.program_id,
                  info.cell.row.original.research,
                ]}
              />
            </MDBox>
          );
        },
      },
      {
        Header: t("cojobtitles"),
        accessor: "cojobtitles",
        Cell: (info) => {
          return (
            <MDBox sx={{ cursor: "pointer" }}>
              <ProductCellWithoutImage
                array={[
                  info.cell.row.original.co_job_title_id,
                  info.cell.row.original.co_user_id,
                ]}
              />
            </MDBox>
          );
        },
      },
      {
        Header: t("oUser"),
        accessor: "jobtitles",
        Cell: (info) => {
          return (
            <MDBox sx={{ cursor: "pointer" }}>
              <ProductCellWithoutImage
                array={[
                  info.cell.row.original.job_title_id,
                  info.cell.row.original.user_id,
                  info.cell.row.original.task_id,
                ]}
              />
            </MDBox>
          );
        },
      },
      {
        Header: t("actions"),
        disableSortBy: true,
        accessor: "",
        Cell: (info) => {
          return (
            <MDBox display="flex" alignItems="center">
              {(ability.can("edit", "works") || isAdmin) && (
                <Tooltip
                  title={t("updateWork")}
                  onClick={(e) => clickEditHandler(info.cell.row.original.id)}
                >
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {(ability.can("delete", "works") || isAdmin) && (
                <Tooltip
                  title={t("deleteItem")}
                  onClick={(e) =>
                    clickDeleteHandler(e, info.cell.row.original.id)
                  }
                >
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </MDBox>
          );
        },
      },
      ,
    ],
    rows: tableData,
  };

  return (
    <DefaultLayout>
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pt={3} pb={3} mb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              {t("works")}
            </MDTypography>
            <MDBox display="flex">
              {(ability.can("create", "works") || isAdmin) && (
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={clickAddHandler}
                  mx={1}
                >
                  + {t("addWork")}
                </MDButton>
              )}
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                type="submit"
                onClick={() => setShowFilter(!showFilter)}
                sx={{ mx: 1 }}
              >
                {t("filter")}
              </MDButton>
            </MDBox>
          </MDBox>
          {showFilter && (
            <FilterWork
              setFilterUrl={setFilterUrl}
              // filterUrl={defaultFilterUrl}
            />
          )}
          <DataTable
            setLimitChange={setLimitChange}
            table={dataTableData}
            meta={meta}
            setPage={setPage}
            setSortVal={setSortVal}
            pageNum={page}
            isSorted={false}
          />
        </Card>
      </MDBox>
    </DefaultLayout>
  );
}

export default Works;
