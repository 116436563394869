/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Trakib components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDAvatar from "components/MDAvatar";
// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";
import { Card } from "@mui/material";
import image from "assets/images/team-4.jpg";
import i18n from "i18n";

function TimelineItem({ color, icon, title, dateTime, description, lastItem, status, avatar, commingDateTime }) {
  const isDark = useTimeline();
  const baseUrl = process.env.REACT_APP_API_URL;
  const { t } = i18n;

  return (
    <MDBox
      position="relative"
      mb={3}
      sx={(theme) => timelineItem(theme, { lastItem, isDark })}
      width="30%"
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color} 
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox
        ml={5.75}
        pt={description ? 0.7 : 0.5}
        lineHeight={0}
        // maxWidth="30rem"
        minWidth="40rem"
      >
        <Card sx={{px:5}}>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox display="flex" alignItems="center">
              <MDBox mr={1}>
                <MDAvatar
                  src={
                    avatar
                      ? `${baseUrl}assets/${avatar}`
                      : image
                  }
                  alt="Avatar"
                  size="xs"
                />
              </MDBox>
              <MDTypography variant="overline">
                {title}
              </MDTypography>
            </MDBox>
            {commingDateTime && <MDBox 
                display="flex"
                alignItems="center"
              >
              <MDTypography 
                variant="h6" 
                fontWeight="medium" 
              >
                  {t('comming_date_time')}:
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium" >
                {commingDateTime}
              </MDTypography>
            </MDBox>}
            <MDBox
              sx={{
                backgroundColor: color,
                borderRadius: 16,
              }}
              px={2}
              display='flex'
              alignItems='center'
            >
              <MDTypography variant="caption" color="white">
                {status}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mt={2} mb={1.5}>
            {description ? (
              <MDTypography variant="h6" color={isDark ? "white" : "dark"}>
                {description}
              </MDTypography>
            ) : null}
          </MDBox>
          <MDBox my={0.5} display='flex' justifyContent='end'>
            <MDTypography variant="caption" color={isDark ? "secondary" : "text"}>
              {dateTime}
            </MDTypography>
          </MDBox>
        </Card>
   
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
