/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete } from "@mui/material";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import CrudService from "services/cruds-service";
import FormField from "layouts/applications/wizard/components/FormField";
// import console from "node:console";
import i18n from "i18n";

const FilterUser = ({ setFilterUrl, filterUrl }) => {
  const [roles, setRoles] = useState([]);
  const { t } = i18n;

  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [value, setValue] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const rolesRes = await CrudService.getAllData("roles?fields=*");
        setRoles(rolesRes.data);
      } catch (err) {
        // console.error(err);
        return null;
      }
    })();
  }, []);

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      // let str = "filterUrl";
      let str = "";
      const values = {
        first_name: user.first_name,
        email: user.email,
        role: value,
      };
      const keys = Object.keys(values);
      keys.forEach((element) => {
        if (values[element] && values[element] !== []) {
          if (typeof values[element] === "object") {
            str += `&filter[${element}]=${values[element].id}`;
          } else {
            str += `&filter[${element}][_contains]=${values[element]}`;
          }
        }
      });
      setFilterUrl(str);
    } catch (err) {
      console.log({ error: true, textError: err.message });
      return null;
    }
  };

  return (
    <MDBox
      component="form"
      method="POST"
      onSubmit={submitHandler}
      encType="multipart/form-data"
      px={5}
    >
      <MDBox display="flex" flexDirection="column" px={3} my={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("firstName")}
              placeholder="Alec"
              name="first_name"
              value={user.first_name}
              onChange={changeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label={t("email")}
              placeholder="example@email.com"
              name="email"
              value={user.email}
              onChange={changeHandler}
            />
          </Grid>
        </Grid>
        <MDBox display="flex" flexDirection="column">
          <MDBox display="flex" flexDirection="column" marginTop="2rem">
            <Autocomplete
              fullWidth
              defaultValue={null}
              options={roles}
              getOptionLabel={(option) => {
                if (option.data) {
                  if (option.data.name) return option.data.name;
                } else {
                  if (option) {
                    if (option.name) return option.name;
                  }
                }
                return "";
              }}
              value={value}
              isOptionEqualToValue={(option, item) =>
                option === item || option.id === item.id
              }
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label={t("role")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </MDBox>
        </MDBox>
        <MDBox ml="auto" mt={4} mb={2} display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="dark" size="small" type="submit">
            {t("search")}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default FilterUser;
