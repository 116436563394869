/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { InputLabel, Autocomplete } from "@mui/material";

// Trakib components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Trakib examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FormField from "layouts/applications/wizard/components/FormField";
import { useNavigate, useParams } from "react-router-dom";

import CrudService from "services/cruds-service";
import i18n from "i18n";

const EditOrderType = () => {
  const { id } = useParams();
  const { t } = i18n;
  const navigate = useNavigate();
  const [jobtitles, setJobTitles] = useState([]);
  const [users, setUsers] = useState([]);
  const [inputs, setInputs] = useState([
    {
      key: "",
      value: "",
    },
  ]);

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        key: "",
        value: "",
      },
    ]);
  };

  const handleChange = (name, index, v) => {
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = v;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const [orderType, setOrderType] = useState({
    id: null,
    title: null,
    user_id: null,
    jobtitle_id: null,
    step2_user_jobtitle: null,
  });

  const [error, setError] = useState({
    title: false,
    user_id: false,
    jobtitle_id: false,
    error: false,
    textError: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await CrudService.getAllData(
          "items/job_title?fields=title,id"
        );
        setJobTitles(data);
      } catch (err) {
        console.error(err);
        return null;
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const response = await CrudService.getAllData(
          `items/order_type/${id}?fields=*,step2_user_jobtitle.id,step2_user_jobtitle.job_title_id.title,step2_user_jobtitle.job_title_id.id
          ,step2_user_jobtitle.user_id.first_name,step2_user_jobtitle.user_id.id`
        );
        const getData = response.data;
        const orderTypeData = {
          id: getData.id,
          title: getData.title,
          jobtitle_id: getData.step2_user_jobtitle.job_title_id,
          user_id: getData.step2_user_jobtitle,
          step2_user_jobtitle: getData.step2_user_jobtitle.id,
        };
        setOrderType(orderTypeData);
        setInputs(getData.details);
        getUsers(getData.step2_user_jobtitle.job_title_id);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [id]);

  const getUsers = async (val) => {
    if (val) {
      const usersRes = await CrudService.getAllData(
        `items/users_job_title?fields=id,job_title_id.id,user_id.id,user_id.first_name&filter[job_title_id]=${val.id}`
      );
      setUsers(usersRes.data);
    }
  };

  const changeHandler = (e) => {
    setOrderType({
      ...orderType,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!orderType.title || orderType.title.trim().length === 0) {
      setError({
        jobtitle_id: false,
        user_id: false,
        title: true,
        textError: t("required"),
      });
      return;
    }

    if (!orderType.jobtitle_id) {
      setError({
        title: false,
        jobtitle_id: true,
        user_id: false,
        textError: t("required"),
      });
      return;
    }

    if (!orderType.user_id) {
      setError({
        title: false,
        jobtitle_id: false,
        user_id: true,
        textError: t("required"),
      });
      return;
    }

    try {
      const newOrderType = {
        title: orderType.title,
        jobtitle_id: orderType.jobtitle_id.id,
        user_id: orderType.user_id.user_id.id,
        step2_user_jobtitle: orderType.user_id.id,
        details: inputs,
      };

      try {
        await CrudService.updateItem(
          `items/order_type/${orderType.id}`,
          newOrderType
        );
        navigate("/orders/order_type", {
          state: { value: true, text: t("updateSuccessfully") },
        });
      } catch (err) {
        if (err.hasOwnProperty("errors")) {
          setError({ ...error, error: true, textError: err.errors[0].detail });
        }
        console.error(err);
      }
    } catch (err) {
      setError({ ...error, error: true, textError: err.message });
      return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadcrumbTitle={`${orderType.title}`} />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {t("updateOrderType")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox
                component="form"
                method="POST"
                onSubmit={submitHandler}
                encType="multipart/form-data"
              >
                <MDBox display="flex" flexDirection="column" px={3} my={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormField
                        label={t("title")}
                        placeholder=""
                        name="title"
                        value={orderType.title}
                        onChange={changeHandler}
                        error={error.title}
                      />
                      {error.title && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </Grid>
                  </Grid>
                  <MDBox display="flex" flexDirection="column">
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={jobtitles}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.title) return option.data.title;
                          } else {
                            if (option) {
                              if (option.title) return option.title;
                            }
                          }
                          return "";
                        }}
                        value={orderType.jobtitle_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={async (event, newValue) => {
                          getUsers(newValue);
                          setOrderType({
                            ...orderType,
                            jobtitle_id: newValue,
                            user_id: "",
                            step2_user_jobtitle: "",
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("jobtitles")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.jobtitle_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      marginTop="2rem"
                    >
                      <Autocomplete
                        defaultValue={null}
                        options={users}
                        getOptionLabel={(option) => {
                          if (option.data) {
                            if (option.data.user_id.first_name)
                              return option.data.user_id.first_name;
                          } else {
                            if (option) {
                              if (option.user_id.first_name)
                                return option.user_id.first_name;
                            }
                          }
                          return "";
                        }}
                        value={orderType.user_id}
                        isOptionEqualToValue={(option, item) =>
                          option === item || option.id === item.id
                        }
                        onChange={(event, newValue) => {
                          setOrderType({ ...orderType, user_id: newValue });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label={t("users")}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {error.user_id && (
                        <MDTypography
                          variant="caption"
                          color="error"
                          fontWeight="light"
                          pl={4}
                        >
                          {error.textError}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>
                  {inputs.map((item, index) => (
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between" // Optional: adjust column spacing
                    >
                      <MDBox flex={1} bgcolor="lightblue" p={2}>
                        <FormField
                          label={t("title")}
                          placeholder=""
                          name="key"
                          onChange={(event) =>
                            handleChange("key", index, event.target.value)
                          }
                          value={item.key}
                        />
                      </MDBox>
                      <MDBox flex={1} bgcolor="lightblue" p={2}>
                        <FormField
                          label={t("value")}
                          placeholder=""
                          name="value"
                          onChange={(event) =>
                            handleChange("value", index, event.target.value)
                          }
                          value={item.value}
                        />
                      </MDBox>
                      <MDBox flex={1} bgcolor="lightblue" p={2}>
                        {inputs.length > 1 && (
                          <MDButton
                            onClick={() => handleDeleteInput(index)}
                            size="large"
                          >
                            -
                          </MDButton>
                        )}
                      </MDBox>
                      <MDBox flex={1} bgcolor="lightblue" p={2}>
                        {index === inputs.length - 1 && (
                          <MDButton
                            onClick={() => handleAddInput()}
                            size="large"
                          >
                            +
                          </MDButton>
                        )}
                      </MDBox>
                    </MDBox>
                  ))}
                  {error.error && (
                    <MDTypography
                      variant="caption"
                      color="error"
                      fontWeight="light"
                      pt={2}
                    >
                      {error.textError}
                    </MDTypography>
                  )}
                  <MDBox
                    ml="auto"
                    mt={4}
                    mb={2}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDBox mx={2}>
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        px={2}
                        mx={2}
                        onClick={() =>
                          navigate("/orders/order_type", {
                            state: { value: false, text: "" },
                          })
                        }
                      >
                        {t("back")}
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      type="submit"
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditOrderType;
