/**
=========================================================
* Muwafiq - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "context";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import ProfilesList from "examples/Lists/ProfilesListNotification";

// Muwafiq components
import MDBox from "components/MDBox";

// Muwafiq examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import EventCalendar from "examples/Calendar";

import moment from "moment";
import CrudService from "services/cruds-service";
import i18n from "i18n";
import DefaultLayout from "examples/LayoutContainers/DefaultLayout";

function Analytics() {
  const navigate = useNavigate();
  const { setIsAuthenticated, getCurrentUser } = useContext(AuthContext);
  const [calenderData, setCalenderData] = useState([]);
  const [myNotifications, setNotifications] = useState([]);
  const [myLinks, setLinks] = useState([]);
  const authContext = useContext(AuthContext);
  const { t } = i18n;

  const getNotificationsAndLinks = async () => {
    const user = await authContext.getCurrentUser();
    let notficationDataRes = await CrudService.getData(
      `uhrapi/notifications?userid=${user.id}&table=notifications`
    );
    setNotifications(notficationDataRes.data);

    let linkDataRes = await CrudService.getData(
      `uhrapi/notifications?userid=${user.id}&table=links`
    );
    setLinks(linkDataRes.data);
  };

  const getCalenData = async (obj) => {
    const user = await authContext.getCurrentUser();
    obj.user_id = user.id;
    let calnDataRes = await CrudService.getCalenderData(obj);
    setCalenderData(calnDataRes.status);
  };

  useEffect(() => {
    async function checkToken() {
      let user = await getCurrentUser();
      if (!user) {
        setIsAuthenticated(false);
        localStorage.removeItem("token");
      }
    }
    checkToken();
    getNotificationsAndLinks();
  }, []);

  const onMonthChangeHandller = (evt) => {
    const calendata = {
      str_date: evt.start,
      end_date: evt.end,
    };
    getCalenData(calendata);
  };

  const handleDateClick = (evt) => {
    console.log(evt.date, evt.dateStr, evt);
    console.log(moment(evt.date).format("YYYY-MM-DD"));
    console.log(moment(evt.date).format("ss:mm:hh"));
  };

  const handleEventClick = (arg) => {
    navigate(`/works/update/${arg.event.id}`);
  };

  return (
    <DefaultLayout>
      <MDBox py={3}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            lg={3}
            // style={{
            //   paddingRight: 60,
            //   paddingLeft: 60,
            // }}
          >
            {myLinks && (
              <ProfilesList
                title={t("links")}
                profiles={myLinks}
                shadow={false}
                style={{
                  backgroundColor: "#fff",
                }}
              />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            lg={3}
            // style={{
            //   paddingRight: 60,
            //   paddingLeft: 60,
            // }}
          >
            {myNotifications && (
              <ProfilesList
                title={t("notifications")}
                profiles={myNotifications}
                shadow={false}
                style={{
                  backgroundColor: "#fff",
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            {useMemo(
              () => (
                <EventCalendar
                  plugins={[
                    "dayGridPlugin",
                    "timeGridPlugin",
                    "interactionPlugin",
                  ]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  initialView="dayGridMonth"
                  initialDate={moment().endOf("month").format("YYYY-MM-DD")}
                  events={calenderData}
                  selectable
                  // editable
                  dateClick={handleDateClick}
                  datesSet={onMonthChangeHandller}
                  // eventContent={renderEventContent} // custom render function
                  eventClick={handleEventClick}
                  // eventsSet={handleEvents}
                />
              ),
              [calenderData]
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DefaultLayout>
  );
}

export default Analytics;
