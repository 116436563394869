/**
=========================================================
* Trakib - v2.1.0
=========================================================

* Product Page: https://www.trakib.com/product/material-dashboard-pro-react
* Copyright 2022 Trakib (https://www.trakib.com)

Coded by www.trakib.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Trakib components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// Trakib examples
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { Tooltip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CrudService from "services/cruds-service";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import i18n from "i18n";
import { AuthContext } from "context";
import DefaultLayout from "examples/LayoutContainers/DefaultLayout";

function Settings() {
  let { state } = useLocation();
  const { t } = i18n;
  const authContext = useContext(AuthContext);
  let { pathname } = useLocation();
  const thisPage = pathname.split("/")[pathname.split("/").length - 1];
  const thisPageParent = pathname.split("/")[pathname.split("/").length - 2];

  const ability = useAbility(AbilityContext);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [sortVal, setSortVal] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [filterUrl, setFilterUrl] = useState("");
  const [filterData, setFilterData] = useState(false);
  const [limitChange, setLimitChange] = useState(false);
  const [notification, setNotification] = useState({
    value: false,
    text: "",
  });

  const reqUrl = `items/${thisPage}?fields=*,count(${thisPageParent})&page=${page}`;

  useEffect(() => {
    (async () => {
      setIsAdmin(await authContext.isCurrentUserAdmin());
    })();
  }, []);

  useEffect(() => {
    setFilterUrl("");
    getItems("");
  }, [pathname]);

  const navigate = useNavigate();

  const getItems = async (filUrl) => {
    const response = await CrudService.getData(`${reqUrl}${filUrl}`);
    setData(response.data);
    setMeta(response.meta);
  };

  useEffect(() => {
    if (limitChange) {
      setLimitChange(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    if (filterData) {
      setFilterData(false);
      return; //to avoid do request two time when it true and false ;now it do only one time when it return to false
    }

    let sortUrl = "";
    if (sortVal) {
      sortUrl = `&sort=${sortVal.desc ? "-" : "+"}${sortVal.id}`;
    }
    data.length > 0 && getItems(`${filterUrl}${sortUrl}`);
  }, [page, limitChange, sortVal, filterData]);

  useEffect(() => {
    if (page !== 1) setPage(1);
    else if (filterUrl !== "") setFilterData(true); // to do any action to fire useEffect which fire when filterData chenged to do getItems function and aproove that sort value exist
  }, [filterUrl]);

  useEffect(() => {
    setTableData(getRows(data));
  }, [data]);

  useEffect(() => {
    if (!state) return;
    setNotification({
      value: state.value,
      text: state.text,
    });
  }, [state]);

  useEffect(() => {
    if (notification.value === true) {
      setTimeout(() => {
        setNotification({
          value: false,
          text: "",
        });
      }, 5000);
    }
  }, [notification]);

  const clickAddHandler = () => {
    navigate(`${pathname}/add`);
  };

  const clickDeleteHandler = async (e, id) => {
    try {
      if (!window.confirm(t("confirmDelete"))) {
        e.nativeEvent.stopImmediatePropagation();
      } else {
        await CrudService.removeItem(`items/${thisPage}/${id}`);
        // the delete does not send a response
        // so I need to get again the items to set it and this way the table gets updated -> it goes to the useEffect with data dependecy
        console.log(`${reqUrl}${filterUrl}`);
        const response = await CrudService.getData(`${reqUrl}${filterUrl}`);
        setData(response.data);
        setNotification({
          value: true,
          text: t("deleteSuccessfully"),
        });
      }
    } catch (err) {
      // it sends error is the category is associated with an item
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        setNotification({
          value: true,
          text: err.errors[0].title,
        });
      }
      return null;
    }
  };

  const getRows = (info) => {
    let updatedInfo = info.map((row) => {
      return {
        id: row.id,
        title: row.title,
        parent: row[`${thisPageParent}_count`],
      };
    });
    return updatedInfo;
  };

  const dataTableData = {
    columns: [
      { Header: t("id"), accessor: "id", width: "25%" },
      { Header: t("title"), accessor: "title", width: "25%" },
      {
        Header: t("actions"),
        disableSortBy: true,
        accessor: "",
        Cell: (info) => {
          return (
            <MDBox display="flex" alignItems="center">
              {(ability.can("delete", "items") || isAdmin) &&
                info.cell.row.original.parent === 0 && (
                  <Tooltip
                    title={t("deleteItem")}
                    onClick={(e) =>
                      clickDeleteHandler(e, info.cell.row.original.id)
                    }
                  >
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {/* {(ability.can("edit", "items") || isAdmin) && (
                <Tooltip
                  title={t("updateItem")}
                  onClick={() => clickEditHandler(info.cell.row.original.id)}
                >
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )} */}
            </MDBox>
          );
        },
      },
    ],

    rows: tableData,
  };

  return (
    <DefaultLayout>
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
      <MDBox pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {t(thisPage)}
              </MDTypography>

              {(ability.can("create", "items") || isAdmin) && (
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  type="submit"
                  onClick={clickAddHandler}
                >
                  + {t("addItem")}
                </MDButton>
              )}
            </MDBox>
            <DataTable
              setLimitChange={setLimitChange}
              table={dataTableData}
              meta={meta}
              setPage={setPage}
              setSortVal={setSortVal}
              pageNum={page}
            />
          </Card>
        </MDBox>
      </MDBox>
    </DefaultLayout>
  );
}

export default Settings;
